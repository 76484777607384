const BUSINESS = "business";
const COMMON = "common"
export const api = {
  business: {
    login: `${BUSINESS}/login`,
    forgotPassword:`${BUSINESS}/forgot-password`,
    otpVerify:`${BUSINESS}/verify-otp`,
    resendOtp:`${BUSINESS}/resend-otp`,
    resetPassword:`${BUSINESS}/reset-password`,
    signup: `${BUSINESS}/sign-up`,
    uploadLogo:`${BUSINESS}/upload-logo`,
    businessImage:`${BUSINESS}/upload-image`,
    updateProfile:`${BUSINESS}/update-profile`,
    getSeat:`${BUSINESS}/get-all-seat`,
    updateSeat:`${BUSINESS}/update-seat`,
    AddSeat:`${BUSINESS}/add-seat`,
    addOffer:`${BUSINESS}/create-offer`,
    registrationPayment:`${BUSINESS}/payment-registration`,
    createOfferPayment : `${BUSINESS}/payment-for-create-offer`,
    updateBusinessDeivceToken:`${BUSINESS}/save_device-token`,
    getAllBookings : `${BUSINESS}/get-all-booking-request`,
    getBusinessOfferTransaction: `${BUSINESS}/get-offer-transaction`,
    editBookingRequest:`${BUSINESS}/edit-booking`,
    getAllFinishOrLiveOffer:`${BUSINESS}/get-all-live-or-finsh-offer`,
    getAllBookingsNotifications : `${BUSINESS}/un-read-notification`,
    getAllRequestedOfferNotification:`${BUSINESS}/get-request-offer-notification`,
    getAllRequestedOffer:`${BUSINESS}/get-all-offer-requested`,
    acceptOrRejectOfferRequest:`${BUSINESS}/accept-or-reject-request-offer`,
    businessPaymentForAcceptedOfferRequest:`${BUSINESS}/payment-for-request-offer`,
    getBusinessCardDetails:`${BUSINESS}/get-card-details`,
    updateBusinessImage:`${BUSINESS}/update-business-image`,
    updateBusinessLogo:`${BUSINESS}/update-business-logo`,  
    updateBusinessAveragePrice:`${BUSINESS}/update-price`,
    getVoucherCost: `${BUSINESS}/get-voucher-amount`,
    getDashboard:`${BUSINESS}/get-dash-board`,
    getCms : `${BUSINESS}/get-cms`,
    updateOffer : `${BUSINESS}/update-offer`,
    readNotification:`${BUSINESS}/read-notification`,
    autoComplete : `${BUSINESS}/place-autocomplete`,
    getPlaceDetailsByPlaceId:`${BUSINESS}/get-place-details-by-place-id` 
  },
  common:{
    getAllSector:`${COMMON}/get-all-sector`,
    getAllVideos:`${COMMON}/get-all-videos`,
    getAllTemplates:`${COMMON}/get-all-template`,
    getAllMasterCosr:`${COMMON}/GetAllCost`
  }
};
