import React from "react";
import Index from "../../componentIndex";
import MenuIcon from "@mui/icons-material/Menu";

const ResponsiveHeader = () => {
  return (
    <Index.Box className="header-responsive-main">
      <Index.Box className="header-responsive">
        <Index.Box className="reslogo">
          <img src={Index.Svg.Logo} alt="" className="reslogo" />
        </Index.Box>
        <Index.Box className="menulogo">
          <Index.Button className="p-0">
            <MenuIcon className="menuimageres" />
          </Index.Button>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default ResponsiveHeader;
