import Frame from "./images/png/frame.png";
import Earn from "./images/png/earning-card.png";
import Offerimg from "./images/png/offer-img.png";
import Offercreation from "./images/png/offer-creation.png";
import Logout from "./images/png/logout.png";
import CrossIcon from './images/png/cross.png'

const Png = {
  Frame,
  Earn,
  Offerimg,
  Offercreation,
  Logout,
  CrossIcon
};

export default Png;
