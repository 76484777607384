import { ToastContainer } from "react-toastify";
import "./assets/styles/admin.css";
import "./assets/styles/responsive.css";
import 'react-toastify/dist/ReactToastify.css';
import AllRoutes from "./routes/Routes";

function App() {
  return (
    <div className="App">
      <ToastContainer/>
      <AllRoutes />
    </div>
  );
}

export default App;
