import Index from "../../componentIndex";
const ApiLoader = () => {
  return (
    <Index.Box className="api-loader">
      <Index.CircularProgress color="warning" />
    </Index.Box>
  );
};

export default ApiLoader;
