export const CONSTANT ={
    // IMAGE_URl:"http://35.177.56.74:3021/uploads/",
    // IMAGE_URl:"https://zebra-api.appworkdemo.com/uploads/",
    // IMAGE_URl:"http://13.43.194.239:3021/uploads/",
    // IMAGE_URl:"https://admin.zeebra-app.com/uploads/",
    IMAGE_URl:"https://zeebra.appworkdemo.com/uploads/",
    GOOGLE_ADDRESS_API_KEY:"AIzaSyDLGdcCaMUGj0JselBgVcMfaooSGW9w6RE",
    MODAL_STYLE :{
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      }
}