import { UseFormSetValue } from "react-hook-form";
import { PaymentState } from "./interfaces";
import Index from "../component/componentIndex";

export const successToast = (message: String) => {
  return Index.toast.success(message, {
    toastId: "customId",
  });
};
export const errorToast = (error: any) => {
  return Index.toast.error(
    error.response.data.message ? error.response.data.message : error.message,
    {
      toastId: "customId",
    }
  );
};
export const formatCardNumber = (
  e: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<PaymentState>
) => {
  const regex = /^[0-9\s\b]+$/;
  if (e.target.value === "" || regex.test(e.target.value)) {
    var v = e.target.value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    var matches = v.match(/\d{4,16}/g);
    var match = (matches && matches[0]) || "";
    var parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return setValue("cardNumber", parts.join(" "), { shouldValidate: true });
    } else {
      return setValue("cardNumber", e.target.value, { shouldValidate: true });
    }
  }
};

export const formatCvv = (
  e: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<PaymentState>
) => {
  return setValue("cvv", e.target.value.slice(0, 3), { shouldValidate: true });
};

export const formatExpireDate = (
  e: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<PaymentState>
) => {
  let inputDate = e.target.value;
  // Remove any non-digit characters from the input
  inputDate = inputDate.replace(/\D/g, "");

  let month = "";
  let year = "";

  // Format the input as "MM/YY"
  if (inputDate.length > 2) {
    month = inputDate.slice(0, 2);
    year = inputDate.slice(2, 4);
    inputDate = `${month}/${year}`;
  }
  // } else if (inputDate.length === 2) {
  //   inputDate = `${inputDate}/`;
  // }

  return setValue("expireDate", inputDate, { shouldValidate: true });
};
