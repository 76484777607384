import Index from "../../../component/componentIndex";

const PageNotFound = () => {
  return (
    <Index.Box sx={{ textAlign: "center", paddingTop: "50px" }}>
      <Index.Typography variant="h4" component="h1" gutterBottom>
        404 - Page Not Found
      </Index.Typography>
      <Index.Typography variant="body1">
        The page you are looking for does not exist.
      </Index.Typography>
    </Index.Box>
  );
};

export default PageNotFound;
