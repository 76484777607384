import React, { Suspense } from "react";
import Loader from "./loader/Loader";

type ComponentType = React.ComponentType<any>;

const Loadable = (Component:ComponentType) => (props:any) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;